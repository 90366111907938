import React from "react"
import "./ThreeCards.css"
import CardOneImage from "./CardOneImage"
import CardTwoImage from "./CardTwoImage"
import CardThreeImage from "./CardThreeImage"
import { BgImage } from "gbimage-bridge"

export default function ThreeCards() {
  const cardOneImg = CardOneImage()
  const CardTwoImg = CardTwoImage()
  const CardThreeImg = CardThreeImage()

  return (
    <div className="card three-cards__container">
      <a
        className="three-cards__container__background-image__text-container__a"
        href="/development"
      >
        <BgImage
          image={cardOneImg}
          className="three-cards__container__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "20rem",
            width: "20rem",
          }}
        >
          <div className="three-cards__container__background-image__text-container">
            <h3 className="three-cards__container__background-image__text-container__h3">
              Development
            </h3>
            <p className="three-cards__container__background-image__text-container__p">
              Website and Web Application development. I can build you a custom
              Web app to suit your needs
            </p>
          </div>
        </BgImage>
      </a>
      <a
        className="three-cards__container__background-image__text-container__a"
        href="/hosting"
      >
        <BgImage
          image={CardTwoImg}
          className="three-cards__container__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "20rem",
            width: "20rem",
          }}
        >
          <div className="three-cards__container__background-image__text-container">
            <h3 className="three-cards__container__background-image__text-container__h3">
              Hosting
            </h3>
            <p className="three-cards__container__background-image__text-container__p">
              Fast hosting services giving your application a boost in
              performance with daily backups for your peace of mind.
            </p>
          </div>
        </BgImage>
      </a>
      <a
        className="three-cards__container__background-image__text-container__a"
        href="/branding"
      >
        <BgImage
          image={CardThreeImg}
          className="three-cards__container__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "20rem",
            width: "20rem",
          }}
        >
          <div className="three-cards__container__background-image__text-container">
            <h3 className="three-cards__container__background-image__text-container__h3">
              Branding & Marketing
            </h3>
            <p className="three-cards__container__background-image__text-container__p">
              The key to a great website design is a strong brand identity. I
              can help you create brand marketing solutions to enhance your
              online presence.
            </p>
          </div>
        </BgImage>
      </a>
    </div>
  )
}
