import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./ImageText.css"
function ImageText() {
  return (
    <div className="card image-text">
      <StaticImage
        className="image-text__static-image"
        src="../../../images/NSC Brand Device Dots Right.png"
        alt="Design Colourful Dots"
      />
      <div className="image-text__text-column image-text__text-column__text">
        <h2 className="image-text__text-column__text__h2">My Vision</h2>
        <p className="image-text__text-column__text__p">
          I want to provide a development process that delivers to your
          expectations with a clear outline and goal. I aim to be the best in
          the business, on the leading edge of security and performance when it
          comes to developing Websites and Web Applications.
        </p>
      </div>
    </div>
  )
}

export default ImageText
