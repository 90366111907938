import React from "react"
import "./TextImageOverImage.css"
import { StaticImage } from "gatsby-plugin-image"

import { BgImage } from "gbimage-bridge"
import ImageProvider from "./ImageProvider"
import ImageProviderMobile from "./ImageProviderMobile"

function TextImageOverImage() {
  const bgImage = ImageProvider()
  const bgImageMobile = ImageProviderMobile()

  return (
    <>
      <div className="card text-image-over-image text-image-over-image--laptop">
        <BgImage
          image={bgImage}
          className="text-image-over-image__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="text-image-over-image__background-image__column-container">
            <div className="text-image-over-image__background-image__column-container__text">
              <h2 className="text-image-over-image__background-image__column-container__text__h2">
                My Values
              </h2>
              <h3 className="text-image-over-image__background-image__column-container__text__h3">
                Communication
              </h3>
              <p className="text-image-over-image__background-image__column-container__text__p">
                "I will communicate everything you need in a clear and concise
                way to guide the success of the project”
              </p>
              <h3 className="text-image-over-image__background-image__column-container__text__h3">
                Discipline
              </h3>
              <p className="text-image-over-image__background-image__column-container__text__p">
                “I aim to be a master at my craft studying every day to be on
                top of my game”
              </p>
              <h3 className="text-image-over-image__background-image__column-container__text__h3">
                Integrity
              </h3>
              <p className="text-image-over-image__background-image__column-container__text__p">
                "I will be honest about my capabilities and what I can offer
                you. I don’t take on a project I cannot deliver.”
              </p>
            </div>
            <StaticImage
              className="text-image-over-image__background-image__column-container__image"
              src="../../../images/Non Stop Coding Website Design Mobile Responsive Renderfolk Mockup.png"
              alt="A Laptop"
            />
          </div>
        </BgImage>
      </div>
      <div className="text-image-over-image text-image-over-image--mobile">
        <BgImage
          image={bgImageMobile}
          className="text-image-over-image__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="text-image-over-image__background-image__column-container--mobile">
            <StaticImage
              className="text-image-over-image__background-image__column-container__image"
              src="../../../images/Non Stop Coding Website Design Mobile Responsive Renderfolk Mockup.png"
              alt="A Laptop"
            />
            <div className="text-image-over-image__background-image__column-container__text">
              <h2 className="text-image-over-image__background-image__column-container__text__h2">
                My Values
              </h2>
              <h3 className="text-image-over-image__background-image__column-container__text__h3">
                Communication
              </h3>
              <p className="text-image-over-image__background-image__column-container__text__p">
                "I will communicate everything you need in a clear and concise
                way to guide the success of the project"
              </p>
              <h3 className="text-image-over-image__background-image__column-container__text__h3">
                Discipline
              </h3>
              <p className="text-image-over-image__background-image__column-container__text__p">
                "I aim to be a master at my craft studying every day to be on
                top of my game"
              </p>
              <h3 className="text-image-over-image__background-image__column-container__text__h3">
                Integrity
              </h3>
              <p className="text-image-over-image__background-image__column-container__text__p">
                "I will be honest about my capabilities and what I can offer
                you. I don’t take on a project I cannot deliver."
              </p>
            </div>
          </div>
        </BgImage>
      </div>
    </>
  )
}

export default TextImageOverImage
