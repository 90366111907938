import * as React from "react"
import ContactForm from "../components/global/contact-form/ContactForm"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TextImage from "../components/home/text-image/TextImage"
import ImageText from "../components/home/image-text/ImageText"
import TextOverImage from "../components/home/text-image-over-image/TextImageOverImage"
import { StaticImage } from "gatsby-plugin-image"
import ThreeCards from "../components/home/three-cards/ThreeCards"
import ThreeImageText from "../components/home/three-image-text/ThreeImageText"
import { motion } from "framer-motion"

export default function IndexPage() {
  return (
    <Layout>
      <Seo title="Home" />

      <motion.h1
        className="layout__h1 glow"
        initial={{
          textShadow: `0 0 10px #fff, 0 0 15px #fff, 0 0 20px #f9294b, 0 0 25px #f9294b,
          0 0 30px #f9294b, 0 0 35px #f9294b, 0 0 40px #f9294b`,
        }}
      >
        NonStop Coding
      </motion.h1>
      <TextImage />
      <ImageText />
      <TextOverImage />
      <div className="layout__static-image--container">
        <StaticImage
          className="layout__static-image"
          src="../images/Non Stop Coding Website Development Desktop.png"
          alt="A Laptop"
        />
      </div>
      <ThreeCards />
      <ThreeImageText />
      <ContactForm />
    </Layout>
  )
}
