import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

export default function CardOneImage() {
  const backgroundImageQueryResult = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "NSC Brand Device Square Up.png" }) {
          id
          childImageSharp {
            gatsbyImageData(quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `
  )
  return getImage(backgroundImageQueryResult.file)
}
