import { StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"
import "./ThreeImageText.css"
import BackgroundImageArrowLeft from "./BackgroundImageArrowLeft"

export default function ThreeImageText() {
  const arrowLeftImage = BackgroundImageArrowLeft()
  return (
    <div className="card three-image-text__container">
      <div className="three-image-text__container__column-one">
        <StaticImage
          className="three-image-text__container__column-one__static-image"
          src="../../../images/Renderfolk Laptop Mockup.png"
          alt="A Laptop"
        />
      </div>
      <div className="three-image-text__container__column-two">
        <BgImage
          image={arrowLeftImage}
          className="three-image-text__container__column-two__background-image"
        >
          <div className="three-image-text__container__column-two__text">
            <h3 className="three-image-text__container__column-two__text__h3">
              My Aim
            </h3>
            <p className="three-image-text__container__column-two__text__p">
              To develop applications that succeed in providing a solution for
              you and your customers.{" "}
            </p>
          </div>
        </BgImage>
      </div>
    </div>
  )
}
