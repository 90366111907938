import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./TextImage.css"
function TextImage() {
  return (
    <div className="card text-image text-image--overlap">
      <div className="text-image__text text-image__text--left-over text-image__text--mobile">
        <h2 className="text-image__text__h2">My Mission</h2>
        <p className="text-image__text__p">
          To develop Websites and Web Applications that are Secure, Performant
          and Reliable!
        </p>
      </div>
      <StaticImage
        className="text-image__static-image text-image__static-image--right-under"
        src="../../../images/nsc-brand-device-laptop-1.png"
        alt="A Laptop"
      />
    </div>
  )
}

export default TextImage
